import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from "./components/home/Home.jsx";
import Player from "./components/player/Player.jsx";
import PodlePlayer from "./components/podlePlayer/PodlePlayer.jsx";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/demo/:project/:productionId">
          <PodlePlayer />
        </Route>
        <Route path="/:client/:newsletterName/:publicationDate">
          <Player />
        </Route>
        <Route path="/:client/:newsletterName">
          <Player />
        </Route>
        <Route path="/figaro/demo">
          <DemoFigaro />
        </Route>

        <Route path="/">
          <Home>
            <h5>Écouter pour s'informer</h5>
          </Home>
        </Route>
      </Switch>
    </Router>
  );
}

const DemoFigaro = (props) => {
  return (
    <Player
      mediaSrc="https://podle-demo.s3.eu-west-3.amazonaws.com/figaro.mpeg"
      coverUrl="https://podle-demo.s3.eu-west-3.amazonaws.com/logo-figaro.png"
      {...props}
    />
  );
};

export default App;
